<template>
    <div>
        <!-- 顶部按钮 -->
        <div class="topbtns">
            <el-button 
                icon="el-icon-zoom-in" 
                type="primary" 
                size="medium" 
                @click="addConfig('add')">
                    添加里程
            </el-button>
        </div>
        <el-table
            :data="mileagelist"
            v-loading="loading"
            :header-cell-style="{background:'#f0f0f0',color:'#666'}">
            <el-table-column align="center" type="index" prop="UserID" label="序号" width="50" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="Type" label="类型" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="Mileage" label="里程(公里)" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="Price" label="里程单价(元)" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="CarLength" label="车长(米)" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="CarLengthPrice" label="车长单价(元)" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" label="操作" width="240">
                <template slot-scope="scope">
                    <el-button type="primary" size="medium" @click="addMileage(scope.row)" icon="el-icon-edit">编辑</el-button>
                    <el-button type="danger" size="medium" @click="delMileage(scope.row)" icon="el-icon-delete">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
         <!-- 添加里程 -->
        <el-dialog class="priceWrap" width="720px" :visible.sync="isPrice">
            <span slot="title" class="dialog-title">
                编辑里程
            </span>
            <!-- 搜索 -->
            <el-form class="manageForm" center :model="addFrom" :inline="true" label-width="70px">
                <el-form-item label="类型" prop="phone">
                    <el-select v-model="addFrom.Type" placeholder="请选择类型">
                        <el-option
                            v-for="item in options"
                            :key="item.type"
                            :label="item.value"
                            :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="里程" prop="Mileage">
                    <el-input 
                        onkeyup="this.value=this.value.replace(/\D/g,'')"
                        v-model="addFrom.Mileage" 
                        placeholder="请输入里程">
                        <template slot="append">米</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="里程单价" prop="Price">
                    <el-input 
                        onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                        v-model="addFrom.Price" 
                        placeholder="请输入里程单价">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="车长" prop="CarLength" v-if="addFrom.Type == 1">
                    <el-select @change="selectchange" v-model="addFrom.carValue" placeholder="请选择类型">
                        <el-option
                            v-for="item in carlist"
                            :key="item.ID"
                            :label="item.value"
                            :value="item.ID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="formBtn">
                    <el-button size="medium" @click="isPrice = false" icon="el-icon-circle-close">取 消</el-button>
                    <el-button type="primary" size="medium" @click="addMileage()" icon="el-icon-circle-plus-outline">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>           
    </div>
</template>

<script>
import {OperateRiskPrice,DeleteRiskPrice} from "@/api/configFlie/index";
export default {
    props:['mileagelist','loading',"carlist"],
    data() {
        return {
            // 添加里程
            isPrice:false,
            // 添加参数
            addFrom:{
                id: 0,
                // 里程
                Mileage:'',
                // 单价
                Price:'',
                // 类型 0-短途，1-长途
                Type:'',
                // 车长
                carLengthID:'',
                carValue:''
            },
            // 类型
            options: [
                {
                    type:0,
                    value:'短途'
                },
                {
                    type:1,
                    value:'长途'
                }
            ],
        }
    },
    methods:{
        selectchange(e){
            this.addFrom.carLengthID = this._props.carlist[e - 1].ID
        },
        // 添加里程
        addConfig(){
            this.addFrom = {
                id: 0,
                Mileage:'',
                Price:'',
                Type:'',
                carLengthID:'',
                carValue:''
            }
            this.isPrice = true
        },
        // 编辑/添加里程
        addMileage(item){
            if(item){
                this.addFrom = {
                    id:item.ID,
                    Mileage:item.Mileage,
                    Price:item.Price,
                    carValue:item.CarLength?`车长：${item.CarLength}米，金额：${item.CarLengthPrice}元`:'',
                    carLengthID:item.carValue,
                    Type:item.Type == '短途'?0:1
                }
                this.isPrice = true
            }else{
                OperateRiskPrice(this.addFrom).then(res=>{
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                    this.$emit('GetRiskPriceList',this.addFrom.id)
                    this.isPrice = false
                })
            }
        },
        // 删除里程
        delMileage(item){
            this.$confirm('是否删除该里程?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                DeleteRiskPrice({ID:item.ID}).then(res=>{
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.$emit('GetRiskPriceList')
                })
            })
        }
    }
}
</script>

<style lang="scss">
    .priceWrap{
        .el-input--suffix .el-input__inner{
            width: 260px;
        }
        .el-input__inner{
            width: 200px;
        }
    }
</style>

<style lang="scss" scoped>
    .topbtns {
        padding-bottom: 10px;
        margin: 0px 0px 10px;
        border-bottom: 1px solid #eee;
    }
    .formBtn{
        text-align: center;
    }
</style>