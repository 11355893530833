<template>
    <div>
        <!-- 顶部按钮 -->
        <div class="topbtns">
            <el-button 
                icon="el-icon-zoom-in" 
                type="primary" 
                size="medium" 
                @click="addConfig()">
                    添加车长
            </el-button>
        </div>
        <el-table
            :data="carlist"
            v-loading="loading"
            :header-cell-style="{background:'#f0f0f0',color:'#666'}">
            <el-table-column align="center" type="index" prop="UserID" label="序号" width="50" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="CarLength" label="车长(米)" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="Price" label="车长单价(元)" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" label="操作" width="240">
                <template slot-scope="scope">
                    <el-button type="primary" size="medium" @click="addConfig(scope.row)" icon="el-icon-edit">编辑</el-button>
                    <el-button type="danger" size="medium" @click="delMileage(scope.row)" icon="el-icon-delete">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 添加里程 -->
        <el-dialog class="carWrap" width="720px" :visible.sync="isPrice">
            <span slot="title" class="dialog-title">
                编辑里程
            </span>
            <!-- 搜索 -->
            <el-form class="manageForm" center :model="addFrom" :inline="true" label-width="70px">
                <el-form-item label="车长" prop="CarLength">
                    <el-input 
                        onkeyup="this.value=this.value.replace(/\D/g,'')"
                        v-model="addFrom.CarLength" 
                        placeholder="请输入车长">
                        <template slot="append">米</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="里程车长" prop="Price">
                    <el-input 
                        onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                        v-model="addFrom.Price" 
                        placeholder="请输入车长单价">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <div class="formBtn">
                    <el-button size="medium" @click="isPrice = false" icon="el-icon-circle-close">取 消</el-button>
                    <el-button type="primary" size="medium" @click="addMileage()" icon="el-icon-circle-plus-outline">确 定</el-button>
                </div>
            </el-form>
        </el-dialog>           
    </div>
</template>

<script>
import {OperateRiskInitiatePrice,DeleteRiskInitiatePrice} from "@/api/configFlie/index";
export default {
    props:['carlist','loading'],
    data() {
        return {
            // 添加车长
            isPrice:false,
            // 添加参数
            addFrom:{
                id: 0,
                // 车长
                CarLength:'',
                // 单价
                Price:''
            },
        }
    },
    methods:{
        // 添加车长
        addConfig(item){
            if(item){
                this.addFrom = item
            }else{
                this.addFrom = {
                    ID: 0,
                    CarLength:'',
                    Price:''
                }
            }
            this.isPrice = true;
        },
        // 编辑/添加车长
        addMileage(){
            OperateRiskInitiatePrice(this.addFrom).then(res=>{
                this.$message({
                    message: '编辑成功',
                    type: 'success'
                });
                this.$emit('GetCarList')
                this.isPrice = false
            })
        },
        // 删除里程
        delMileage(item){
            this.$confirm('是否删除该车长?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                DeleteRiskInitiatePrice({ID:item.ID}).then(res=>{
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.$emit('GetCarList')
                })
            })
        }
    }
}
</script>

<style lang="scss">
    .carWrap{
        .el-input__inner{
            width: 200px;
        }
    }
</style>

<style lang="scss" scoped>
    .topbtns {
        padding-bottom: 10px;
        margin: 0px 0px 10px;
        border-bottom: 1px solid #eee;
    }
    .formBtn{
        text-align: center;
    }
</style>