<template>
  <div>
    <el-table
      :data="upFilelist"
      v-loading="loading"
      :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
    >
      <el-table-column
        align="center"
        type="index"
        prop="RowIndex"
        label="序号"
        width="50"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="FileTypeName"
        label="文件类型名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="Suffix"
        label="文件后缀"
        width="400"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="FileSize"
        label="文件大小"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="Timeout"
        label="超时时间(s)"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="AllowNoSuffix"
        label="是否允许无后缀上传"
        show-overflow-tooltip
      >
        <template slot-scope="scoped">
          <el-tag :type="scoped.row.AllowNoSuffix ? 'success' : 'warning'">{{
            scoped.row.AllowNoSuffix ? "允许" : "不允许"
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="OSSPath"
        label="OSS文件目录"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        prop="Remark"
        label="备注"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column align="center" label="操作" width="240">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="medium"
            @click="editConfig(scope.row)"
            icon="el-icon-edit"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改文件类型 -->
    <el-dialog class="carWrap" width="720px" :visible.sync="isUpFile">
      <span slot="title" class="dialog-title">
        编辑文件上传配置
      </span>
      <!-- 搜索 -->
      <el-form class="manageForm" center :model="addFrom" label-width="100px">
        <el-form-item label="文件大小" prop="FileSize">
          <el-input
            v-model.trim="addFrom.FileSize"
            placeholder="请输入文件大小"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="文件后缀" prop="Suffix">
          <el-input v-model.trim="addFrom.Suffix" placeholder="请输入文件后缀">
          </el-input>
        </el-form-item>
        <el-form-item label="无后缀上传" prop="AllowNoSuffix">
          <el-select
            v-model="addFrom.AllowNoSuffix"
            placeholder="请选择是否允许"
          >
            <el-option label="允许" :value="true"> </el-option>
            <el-option label="不允许" :value="false"> </el-option>
          </el-select>
        </el-form-item>
        <div class="formBtn">
          <el-button
            size="medium"
            @click="isUpFile = false"
            icon="el-icon-circle-close"
            >取 消</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="addMileage()"
            icon="el-icon-circle-plus-outline"
            >确 定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  OperateRiskInitiatePrice,
  DeleteRiskInitiatePrice,
} from "@/api/configFlie/index";
import { upFileListEdit } from "@/api/configFlie/index.js";
export default {
  props: ["upFilelist", "loading"],
  data() {
    return {
      // 上传文件配置弹框
      isUpFile: false,
      // 添加参数
      addFrom: {
        AllowNoSuffix: true,
        FileSize: "",
        Suffix: "",
      },
    };
  },
  methods: {
    // 编辑文件类型
    editConfig(item) {
      if (item) {
        this.addFrom = {
          ...item,
          isUpdate: true,
        };
      } else {
        this.addFrom = {
          AllowNoSuffix: true,
          FileSize: "",
          Suffix: "",
        };
      }
      this.isUpFile = true;
    },
    // 编辑/添加编辑文件类型
    addMileage() {
      upFileListEdit(this.addFrom).then((res) => {
        this.$message({
          message: "编辑成功",
          type: "success",
        });
        this.$emit("GetUpFileList");
        this.isUpFile = false;
      });
    },
    // 删除编辑文件类型
    // delMileage(item) {
    //   this.$confirm("是否删除该车长?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(() => {
    //     DeleteRiskInitiatePrice({ ID: item.ID }).then((res) => {
    //       this.$message({
    //         message: "删除成功",
    //         type: "success",
    //       });
    //       this.$emit("GetUpFileList");
    //     });
    //   });
    // },
  },
};
</script>

<style lang="scss">
.carWrap {
  .el-input__inner {
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
.topbtns {
  padding-bottom: 10px;
  margin: 0px 0px 10px;
  border-bottom: 1px solid #eee;
}
.formBtn {
  text-align: center;
}
</style>
