import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

// 获取风控配置列表
export const GetConfigList = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/PlatFormRisk/GetConfigList`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 修改基础配置
export const UpdateConfigList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/PlatFormRisk/UpdateConfigList?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 通过手机号码搜索 添加白名单
export const GetEnterList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/PlatFormRisk/GetEnterList?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 添加/修改白名单
export const addWhiteList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatFormRisk/OperateRiskWhite`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取白名单列表
export const GetRiskWhiteList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatFormRisk/GetRiskWhiteList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 删除白名单
export const DeleteRiskWhite = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/PlatFormRisk/DeleteRiskWhite?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 查询车长
export const GetCarList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatFormRisk/GetRiskInitiatePriceList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 添加里程
export const OperateRiskPrice = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatFormRisk/OperateRiskPrice`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 查询里程列表
export const GetRiskPriceList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatFormRisk/GetRiskPriceList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 删除里程
export const DeleteRiskPrice = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/PlatFormRisk/DeleteRiskPrice?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 添加车长
export const OperateRiskInitiatePrice = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatFormRisk/OperateRiskInitiatePrice`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 删除车长
export const DeleteRiskInitiatePrice = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/PlatFormRisk/DeleteRiskInitiatePrice?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 用户配置 获取用户列表
export const GetUsersList = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/PlatFormUser/GetUsersList`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 修改用户配置
export const OperateUsers = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/PlatFormUser/OperateUsers`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 用户启用/删除
export const UserEnabled = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/UserRole/UserEnabled`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 上传文件用户配置列表
export const upFileList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${host}/api/v2/SysUploadFileCfg/ Get/SysUploadFileCfg/page`,
        params
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 修改上传文件用户配置列表
export const upFileListEdit = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/SysUploadFileCfg/ Get/SysUploadFileCfg/Add`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 登录错误次数过多解锁
export const DeblockingLoginErrorPhone = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/v2/Sys/DeblockingLoginErrorPhone/${params.phone}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取用户自动授权协议
export const GetUserAutoSignContract = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/FadadaElectronTag/GetUserAutoSignContract?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取配置项目
export const GetSysValueAsync = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/v2/Sys/GetSysValueAsync?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 更新项目配置
export const EditSysConfig = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/Sys/EditSysConfig`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 查询结算单关键信息--admin
export const GetInvoiceTypes = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/v2/InvoiceTask/GetInvoiceTypes?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 分账解冻
export const UnFroze = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/Yumin/Ledger/UnFroze?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 提现业务控制
export const WithdrawalControl = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/Yumin/Cash/WithdrawalControl`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 修改运单库场景类型
export const UpdateWaybillTaskscene = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/WayBill/UpdateWaybillTaskscene`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 修改人工审核服务商
export const UpdateManualProvider = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/WayBillManual/UpdateManualProvider`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 刷新ocr配置
export const RefreshOcr = (params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/v2/Sys/RefreshAliyunInfo`).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};
