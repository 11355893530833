<template>
    <div>
        <el-form class="manageForm" :model="searchData" :inline="true" label-width="70px">
            <el-form-item label="类型" prop="TypeName">
                <el-input class="fromInp" v-model="searchData.TypeName" placeholder="输入类型" @keyup.enter.native="searchFrom()"></el-input>
            </el-form-item>
            <el-form-item label="名称" prop="Name">
                <el-input class="fromInp" v-model="searchData.Name" placeholder="输入名称" @keyup.enter.native="searchFrom()"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="medium" @click="searchFrom()" icon="el-icon-search">搜索</el-button>
                <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="configlist" :header-cell-style="{background:'#f0f0f0',color:'#666'}" v-loading="loading">
            <el-table-column align="center" type="index" label="序号" width="50" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="TypeName" label="类型" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="Name" label="名称" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="Remark" label="备注" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="RuleValue" label="已配置数值" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-if="scope.row.RuleValue == 'true'">
                        <el-tag type="success">已启用</el-tag>
                    </div>
                    <div v-if="scope.row.RuleValue == 'false'">
                        <el-tag type="danger">已关闭</el-tag>
                    </div>
                    <div v-if="scope.row.RuleValue != 'false' && scope.row.RuleValue != 'true'">
                        {{scope.row.RuleValue}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="120">
                <template slot-scope="scope">
                    <el-button type="primary" size="medium" @click="compileConfig(scope.row)" icon="el-icon-edit">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 编辑弹窗 -->
        <el-dialog class="addDialog" width="900px" :visible.sync="isConfig" v-if="isConfig">
            <span slot="title" class="dialog-title">
                编辑
            </span>
            <el-table :data="configItem" :header-cell-style="{background:'#f0f0f0',color:'#666'}">
                <el-table-column align="center" type="index" label="序号" width="50" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="TypeName" label="类型" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="Name" label="名称" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="Remark" label="备注" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="RuleValue" label="已配置数值" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-if="scope.row.RuleValue == 'true'">
                            <el-tag type="success">已启用</el-tag>
                        </div>
                        <div v-if="scope.row.RuleValue == 'false'">
                            <el-tag type="danger">已关闭</el-tag>
                        </div>
                        <div v-if="scope.row.RuleValue != 'false' && scope.row.RuleValue != 'true'">
                            {{scope.row.RuleValue}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <div v-if="scope.row.RuleValue != 'false' && scope.row.RuleValue != 'true'">
                            <el-input
                                placeholder="请输入"
                                v-model="textValue"
                                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                clearable>
                            </el-input>
                        </div>
                        <div v-else>
                            <el-switch
                                v-model="switchValue"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isConfig = false">取 消</el-button>
                <el-button type="primary" @click="upDateConfig()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {UpdateConfigList} from "@/api/configFlie/index";
export default {
    props:["configlist","loading"],
    data() {
        return {
            // 搜索参数
            searchData:{
                TypeName:'',
                Name:""
            },
            // 编辑弹窗
            isConfig:false,
            // 单条配置信息
            configItem:[],
            // 是否开启
            switchValue:false,
            // 输入框
            textValue:"",
            // 类型
            ruleType:''
        }
    },
    methods:{
        // 修改配置
        upDateConfig(){
            let data = {
                ID:this.configItem[0].ID,
                RuleValue:this.configItem[0].RuleValueType == 2?this.switchValue:this.textValue
            }
            this.$confirm('是否修改该风控配置?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                UpdateConfigList(data).then(res=>{
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.isConfig = false;
                    this.$emit("GetConfigList")
                })
            })
        },
        // 编辑
        compileConfig(item){
            this.configItem[0] = item
            this.ruleType = item.RuleValueType
            if(item.RuleValueType == 2){
                this.switchValue = item.RuleValue == "true"?true:false;
            }else{
                this.textValue = item.RuleValue
            }
            this.isConfig = true
        },
        // 搜索
        searchFrom(){
            this.$emit("searchFrom",this.searchData.TypeName,this.searchData.Name)
        },
        // 清空搜索
        resetForm(){
            this.searchData = {
                TypeName:'',
                Name:""
            }
            this.$emit("resetForm")
        },
    }
}
</script>

<style lang="scss">
.addDialog{
    .el-dialog__footer{
        border-top:0 !important;
    }
    .el-dialog__header{
        border-bottom: 1px solid #eee;
    }
    .el-dialog__footer{
        text-align: right !important;
    }
}
</style>

<style lang="scss" scoped>
.addDialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-dialog__footer{
    border-top: 1px solid #eee;
    text-align: center;
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>