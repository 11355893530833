<template>
  <div>
    <div class="facilityBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基础配置" name="first">
          <deployConfig :configlist="configlist" :loading="loading" @searchFrom="searchFrom" @resetForm="resetForm"
            @GetConfigList="GetConfigList">
          </deployConfig>
        </el-tab-pane>
        <el-tab-pane label="升级配置" name="upgradeSetting">
          <div style="margin: 2% 0px 0px 12px;" v-for="(item, index) in settingList" :key="index">
            <div style="text-align:left;margin-bottom: 15px;font-weight: bold;font-size: 14px;">{{ item.ruleName }}：</div>
            <div style="display: flex;align-items:center ">
              <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入内容" v-model="item.sysValue"
                style="width: 400px">
              </el-input>
              <el-button type="primary" @click="toUpgrade(item)" style="margin-left: 20px;">更新</el-button>
            </div>
          </div>
          <div style="margin: 4% 0px 0px 12px;display: flex;align-items: center;">
            <div style="text-align:left;font-weight: bold;font-size: 14px;">司机提现合同签署控制：</div>
            <el-switch v-model="driverTaxSwitchInfo.KeyValue" active-color="#13ce66" inactive-color="#ccc" active-text="开"
              inactive-text="关" :active-value="1" :inactive-value="0" style="width: 248px">
            </el-switch>
            <el-button type="primary" @click="toUpgradeDriverTaxSwitch()" style="margin-left: 20px;">更新</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="白名单" name="second">
          <whiteConfig :riskWhiteList="riskWhiteList" :loading="loading" @GetRiskWhiteList="GetRiskWhiteList">
          </whiteConfig>
        </el-tab-pane>
        <el-tab-pane label="车长单价表" name="length">
          <lengthConfig :carlist="carlist" :loading="loading" @GetCarList="GetCarList">
          </lengthConfig>
        </el-tab-pane>
        <el-tab-pane label="里程单价表" name="third">
          <priceConfig :mileagelist="mileagelist" :loading="loading" :carlist="carlist"
            @GetRiskPriceList="GetRiskPriceList">
          </priceConfig>
        </el-tab-pane>
        <el-tab-pane label="文件上传配置" name="upFile">
          <upFileConfig :loading="loading" :upFilelist="upFilelist" @GetUpFileList="GetUpFileList">
          </upFileConfig>
        </el-tab-pane>
        <el-tab-pane label="解锁账号" name="deblocking">
          <div style="margin: 5% 0px 0px 15%;">
            <el-input v-model="phone" maxlength="11" placeholder="请输入手机号码" style="width: 300px" />
            <el-button type="primary" @click="toDeblocking" style="margin-left: 20px;">解锁</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-pagination background class="pagination" @current-change="configChange" :current-page.sync="configData.page"
        :page-size="configData.pagesize" layout="total, prev, pager, next, jumper" :total="configData.total"
        v-if="activeName != 'deblocking' && activeName != 'upgradeSetting'">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import lengthConfig from "@/components/businessCmpt/configFlie/lengthConfig";
import deployConfig from "@/components/businessCmpt/configFlie/deployConfig";
import priceConfig from "@/components/businessCmpt/configFlie/priceConfig";
import whiteConfig from "@/components/businessCmpt/configFlie/whiteConfig";
import upFileConfig from "@/components/businessCmpt/configFlie/upFileConfig";
import {
  GetCarList,
  GetConfigList,
  GetRiskWhiteList,
  GetRiskPriceList,
  upFileList,
  DeblockingLoginErrorPhone,
  GetSysValueAsync,
  EditSysConfig
} from "@/api/configFlie/index";
import verify from '@/utils/verify.js'
export default {
  data() {
    return {
      //分页控件相关参数
      configData: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0,
      },
      sysValue: '',
      sysValueInfo: {},
      phone: '', // 需要解锁的手机号
      // 默认显示Tab
      activeName: "first",
      // 搜索条件
      searchData: {
        TypeName: "",
        Name: "",
        PageSize: 10,
        PageIndex: 1,
      },
      // 配置数据
      configlist: [],
      // 表格loading
      loading: true,
      // 白名单列表
      riskWhiteList: [],
      // 里程列表
      mileagelist: [],
      // 车长列表
      carlist: [],
      // 上传文件列表
      upFilelist: [],
      settingList: [
        {
          ruleName: '人工审核不能提交的规则',
          sysValue: '',
          sysValueInfo: {},
          keyType: 'P00001',
          keyName: 'ManualConntRule',
          type: 'string'
        },
        {
          ruleName: '网络货运导入司机车辆最大条数',
          sysValue: '',
          sysValueInfo: {},
          keyType: 'P00001',
          keyName: 'TempTransportUploadConut',
          type: 'int'
        },
        {
          ruleName: '普通运力表格导入最大数量',
          sysValue: '',
          sysValueInfo: {},
          keyType: 'Capacity',
          keyName: 'CapacityExcelBigNum',
          type: 'int'
        },
        {
          ruleName: '开发平台地址配置',
          sysValue: '',
          sysValueInfo: {},
          keyType: 'OpenApi',
          keyName: 'OpenApiUrl',
          type: 'string'
        }

      ],
      driverTaxSwitchInfo: {}
    };
  },
  created() {
    // 获取风控配置列表
    this.GetConfigList();
  },
  methods: {
    // 切换Tab栏
    handleClick(tab) {
      this.activeName = tab.name;
      this.searchData.PageIndex = 1;
      if (tab.name == "first") {
        // 获取风控配置列表
        this.GetConfigList();
      } else if (tab.name == "second") {
        // 获取白名单列表
        this.GetRiskWhiteList({ 'searchForm': { EnterpriseFullName: '' }, 'type': '' });
      } else if (tab.name == "third") {
        // 查询里程列表
        this.GetRiskPriceList();
        // 查询车长列表
        this.GetCarList("third");
      } else if (tab.name == "upFile") {
        // 查询上传文件配置列表
        this.GetUpFileList();
      } else if (tab.name == "upgradeSetting") {
        // 获取配置项目
        this.getSysValueAsync();
        // 获取司机提现合同签署控制
        this.getDriverTaxSwitch()
      } else {
        // 查询车长列表
        this.GetCarList();
      }
    },
    // 司机提现合同签署控制
    getDriverTaxSwitch() {
      GetSysValueAsync({
        keyType: 'P00001',
        keyName: 'DriverTaxSwitch'
      }).then(res => {
        this.driverTaxSwitchInfo = res.data || {}
      })
    },
    // 更新配置
    toUpgradeDriverTaxSwitch() {
      this.$confirm('确定更新吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        EditSysConfig(this.driverTaxSwitchInfo).then(res => {
          this.$message.success("更新成功")
          this.getDriverTaxSwitch()
        })
      });
    },
    // 查询配置
    getSysValueAsync() {
      for (let i = 0; i < this.settingList.length; i++) {
        let obj = this.settingList[i]
        GetSysValueAsync({
          keyType: obj.keyType,
          keyName: obj.keyName
        }).then(res => {
          obj.sysValueInfo = res.data || {}
          obj.sysValue = obj.type == 'string' ? obj.sysValueInfo.KeyText : obj.sysValueInfo.KeyValue
        })
      }
    },
    // 更新配置
    toUpgrade(item) {
      if (!item.sysValue) {
        this.$message.error("请输入内容");
        return
      }
      this.$confirm('确定更新配置吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        EditSysConfig({
          ...item.sysValueInfo,
          ...(item.type == 'string' ? { KeyText: item.sysValue } : { KeyValue: item.sysValue })
        }).then(res => {
          this.$message.success("更新成功")
          this.getSysValueAsync()
        })
      });
    },
    // 解锁账号
    toDeblocking() {
      if (!this.phone) {
        this.$message.error("请输入手机号");
        return
      }
      if (!verify._phone(this.phone)) {
        this.$message.error("请输入正确的手机号");
        return
      }
      this.$confirm('确定解锁此账号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeblockingLoginErrorPhone({ phone: this.phone }).then(res => {
          this.$message.success("解锁成功")
          this.phone = ''
        })
      });
    },
    // 车长列表
    GetCarList(type) {
      this.loading = true;

      let data = {
        PageSize: this.searchData.PageSize,
        PageIndex: this.searchData.PageIndex,
      };
      if (type == "third") {
        data.PageSize = 10000;
      }
      GetCarList({ Json: JSON.stringify(data) })
        .then((res) => {
          if (type == "third") {
            res.data.DataList.forEach((item) => {
              item.value = `车长：${item.CarLength}米，金额：${item.Price}元`;
            });
          }
          this.carlist = res.data.DataList;
          this.configData.total = res.data.TotalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查询里程列表
    GetRiskPriceList(id) {
      this.loading = true;
      let data = {
        PageSize: this.searchData.PageSize,
        PageIndex: this.searchData.PageIndex,
      };
      if (id == 0) {
        data.PageIndex = 1;
      }
      GetRiskPriceList({ Json: JSON.stringify(data) })
        .then((res) => {
          this.mileagelist = res.data.DataList;
          this.configData.total = res.data.TotalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取白名单列表
    GetRiskWhiteList(obj) {
      console.log(obj)
      this.loading = true;
      let data = {
        PageSize: this.searchData.PageSize,
        PageIndex: this.searchData.PageIndex,
        ...obj.searchForm
      };
      if (obj.type == "add") {
        data.PageIndex = 1;
        data.EnterpriseFullName = ''
      }
      GetRiskWhiteList({ Json: JSON.stringify(data) })
        .then((res) => {
          this.riskWhiteList = res.data.DataList;
          this.configData.total = res.data.TotalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 搜索配置
    searchFrom(TypeName, Name) {
      this.searchData = {
        TypeName,
        Name,
      };
      // 获取风控配置列表
      this.GetConfigList();
    },
    // 清空搜索
    resetForm() {
      this.searchData = {
        TypeName: "",
        Name: "",
      };
      // 获取风控配置列表
      this.GetConfigList();
    },
    // 分页请求
    configChange(e) {
      // this.configData.page = e;
      this.searchData.PageIndex = e;
      if (this.activeName == "first") {
        // 获取风控配置列表
        this.GetConfigList();
      } else if (this.activeName == "second") {
        // 获取白名单列表
        this.GetRiskWhiteList();
      } else if (this.activeName == "third") {
        // 查询里程列表
        this.GetRiskPriceList();
      } else if (this.activeName == "upFile") {
        // 获取上传文件配置列表
        this.GetUpFileList();
      }
    },
    // 获取风控配置列表
    GetConfigList() {
      this.loading = true;
      GetConfigList({ Json: JSON.stringify(this.searchData) })
        .then((res) => {
          this.configlist = res.data.DataList;
          this.configData.total = res.data.TotalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取上传文件配置列表
    GetUpFileList() {
      this.loading = true;
      let data = {
        PageSize: this.searchData.PageSize,
        PageIndex: this.searchData.PageIndex,
      };

      upFileList(data)
        .then((res) => {
          this.upFilelist = res.data.DataList;
          console.log("this.upFilelist", this.upFilelist);
          this.configData.total = res.data.TotalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    deployConfig,
    whiteConfig,
    priceConfig,
    lengthConfig,
    upFileConfig,
  },
};
</script>

<style lang="scss" scoped></style>
